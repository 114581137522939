import fetchHtml from '../data/fetchHtml.js';
import render from '../rendering/render.js';

/**
 * A route handler for visiting a blog post by it's URI.
 *
 * @return {import('./RouteHandler.js').RouteHandler}
 */
export default function goToPost() {
	return (location, [, postId]) => Promise.all([
		import('../rendering/renderPost.js'),
		fetchHtml(`/blog/${postId}`)
	])
		.then(render);
}
