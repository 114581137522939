import { deserialize } from '@ultraq/dom-utils';

/**
 * Given a tuple of a render function and an HTML string, execute the renderer.
 *
 * @param {[{ default: (document: Document) => void }, string]} tuple
 */
export default function render([{ default: renderer }, html]) {
	renderer(deserialize(html));
}
