import fetchHtml from '../data/fetchHtml.js';
import render from '../rendering/render.js';

/**
 * A route handler for going to the About page.
 *
 * @return {import('./RouteHandler.js').RouteHandler}
 */
export default function goToAbout() {
	return () => Promise.all([
		import('../rendering/renderAbout.js'),
		fetchHtml('/about')
	])
		.then(render);
}
