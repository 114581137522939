import fetchHtml from '../data/fetchHtml.js';
import render from '../rendering/render.js';

/**
 * Build a route handler for viewing a writing item by its URI.
 *
 * @return {import('./RouteHandler.js').RouteHandler}
 */
export default function goToWriting() {
	return (location, [, writingId]) => Promise.all([
		import('../rendering/renderWriting.js'),
		fetchHtml(`/writing/${writingId}`)
	])
		.then(render);
};
