import { html } from '../utilities/Html.js';

import { $ } from 'dumb-query-selector';

/**
 * Inserts the generate loader HTML into the document.
 *
 * @param {string} [loadingText]
 */
export function addLoader(loadingText = 'Loading...') {
	document.body.appendChild(html`
		<div class="moocow-loading">
			${loadingText}
		</div>
	`);
}

/**
 * Inserts the route loader HTML into the document.
 *
 * @param {string} [loadingText]
 */
export function addRouteLoader(loadingText = 'Loading...') {
	document.body.appendChild(html`
		<div class="moocow-loading moocow-loading-delay-for-route">
			${loadingText}
		</div>
	`);
}

/**
 * Removes the route loader HTML from the document.
 */
export function removeLoader() {
	$('.moocow-loading').remove();
}
