/**
 * A route handler for hitting the server with whatever URL is given.  Used for
 * the **everything** page, but any other route that could result in an
 * appropriate error page, like a 404.
 *
 * @return {import('./RouteHandler.js').RouteHandler}
 */
export default function goToServer() {
	return (location) => new Promise(() => {
		window.location.href = location.pathname;
		// Promise never resolves, the redirect will terminate this script
	});
};
