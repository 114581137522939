import App from './App.js';

import { parseJsonFromElement } from '@ultraq/dom-utils';
import { $$ } from 'dumb-query-selector';
import { createBrowserHistory } from 'history';

/**
 * Entry point for the MooCow website, sets up some client-side scripts for
 * animated route transitions to take over after the server has given us our
 * first page.
 */

// Substitute the special tokens in a no-spam address for their functional e-mail counterparts.
$$("a[rel='email']").forEach(element => {
	element.href = element.href
		.replace(/\[AT\]/, '@')
		.replace(/\[DOT\]/g, '.');
});

// Start the dynamic page transitions version of the website
new App(createBrowserHistory(), parseJsonFromElement('#data'));
