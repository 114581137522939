import fetchHtml from '../data/fetchHtml.js';
import render from '../rendering/render.js';

/**
 * Build a route handler for visiting a list of posts by their category.
 *
 * @return {import('./RouteHandler.js').RouteHandler}
 */
export default function goToPostsForCategory() {
	return (location, [, category]) => Promise.all([
		import('../rendering/renderPosts.js'),
		fetchHtml(`/blog/category/${category}`)
	])
		.then(render);
}
