import { checkStatus, responseAsText } from '@ultraq/fetch-utils';

/**
 * Fetch HTML data from an API endpoint and return it as a string.
 *
 * @param {string} path
 * @return {Promise<string>}
 */
export default function fetchHtml(path) {
	return fetch(path, {
		headers: {
			'Accept': 'text/html'
		}
	})
		.then(checkStatus)
		.then(responseAsText);
}
