import fetchHtml from '../data/fetchHtml.js';
import render from '../rendering/render.js';

/**
 * Build a route handler for viewing an artwork by its URI.
 *
 * @return {import('./RouteHandler.js').RouteHandler}
 */
export default function goToArtwork() {
	return (location, [, artworkId]) => Promise.all([
		import('../rendering/renderArtwork.js'),
		fetchHtml(`/artwork/${artworkId}`)
	])
		.then(render);
}
