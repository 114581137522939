import fetchHtml from '../data/fetchHtml.js';
import render from '../rendering/render.js';

/**
 * Build a route handler for going to the latest post.
 *
 * @return {import('./RouteHandler.js').RouteHandler}
 */
export default function goToLatestPost() {
	return () => Promise.all([
		import('../rendering/renderPost.js'),
		fetchHtml('/blog/')
	])
		.then(render);
}
