import { addRouteLoader, removeLoader } from './components/PageLoader.js';
import Router from './routing/Router.js';
import goToAbout from './routing/goToAbout.js';
import goToArtwork from './routing/goToArtwork.js';
import goToArtworks from './routing/goToArtworks.js';
import goToArtworksForCategory from './routing/goToArtworksForCategory.js';
import goToLatestPost from './routing/goToLatestPost.js';
import goToPost from './routing/goToPost.js';
import goToPostsForCategory from './routing/goToPostsForCategory.js';
import goToServer from './routing/goToServer.js';
import goToWriting from './routing/goToWriting.js';
import goToWritings from './routing/goToWritings.js';
import { isAuthenticated } from './utilities/Security.js';
import { supportsParallaxEffect } from './utilities/Support.js';

import { addEventDelegate } from '@ultraq/dom-utils';
import { delay } from '@ultraq/promise-utils';
import { $ } from 'dumb-query-selector';

/**
 * Main controller for the MooCow app.
 */
export default class App {

	/**
	 * Start the app with the given components.
	 *
	 * @param {History} history
	 * @param {object} config
	 */
	constructor(history, config) {

		// Apply router for handling URL changes
		new Router(history, this.buildRoutes());

		// Enable client-side routing for all local links
		addEventDelegate(document.body, 'click', 'a[href^="/"], a[href^="/"] img', event => {
			let href = event.target.closest('a').getAttribute('href');

			// Make an exception for the /feed URL
			if (href !== '/feed') {
				event.preventDefault();
				history.push(href);
			}
		});

		// Enable client-side editing of items
		if (isAuthenticated()) {
			addEventDelegate(document.body, 'click', '#edit', event => {
				if (!event.defaultPrevented) {
					// Reload the page in editing state to get control in the client
					history.replace(window.location.pathname, {
						editing: true
					});
				}
			});
		}
	}

	/**
	 * Return the collection of default routes and their associated data-retrieval
	 * actions.
	 *
	 * @private
	 * @return {Record<string, import('./routing/RouteHandler.js').RouteHandler>}
	 */
	buildRoutes() {

		let transitionRouter = new TransitionRouter();

		return Object.entries({
			'/blog/category/:category':    goToPostsForCategory,
			// '/blog/new':                   goToNewPost,
			'/blog/:postUri':              goToPost,
			'/blog/':                      goToLatestPost,
			'/artwork/category/:category': goToArtworksForCategory,
			'/artwork/:artworkUri':        goToArtwork,
			'/artwork/':                   goToArtworks,
			'/writing/:writingUri':        goToWriting,
			'/writing/':                   goToWritings,
			'/about':                      goToAbout,
			'/all/':                       goToServer,
			'/':                           goToLatestPost,

			// A catch-all for unhandled routes, goes to the server and usually results in 404
			'/(.*)': goToServer
		})
			.reduce((acc, [path, handler]) => {
				acc[path] = transitionRouter.build(handler());
				return acc;
			}, {});
	}
}

/**
 * A class for creating animated transitions between routes.
 */
class TransitionRouter {

	/**
	 * @type {Element[]}
	 */
	routeElements = [];

	transitionLengthMs = 400;

	/**
	 * Initiate the router with the current DOM structure.
	 */
	constructor() {

		// Mark certain HTML elements as animatable for route transitions
		this.routeElements = [
			$('.moocow-parallax--layer'),
			$('.moocow-content'),
			$('.moocow-sitefooter')
		];
		this.routeElements.forEach(routeElement => routeElement.classList.add('moocow-route'));
	}

	/**
	 * Build a route transition for use later.  Performs the same steps as
	 * {@link #start} when called at that later time.
	 *
	 * @param {import('./routing/RouteHandler.js').RouteHandler} routeHandler
	 * @return {import('./routing/RouteHandler.js').RouteHandler}
	 */
	build(routeHandler) {

		return (...args) => this.start(routeHandler, ...args);
	}

	/**
	 * Begin a route transition, applying the necessary styling to the page in
	 * preparation, then calling the given function to perform the page update,
	 * after which the transition is completed.
	 *
	 * @param {import('./routing/RouteHandler.js').RouteHandler} routeHandler
	 * @param {import('history').Location} location
	 * @param {string[]} pathParts
	 * @return {Promise<void>}
	 */
	start(routeHandler, location, pathParts) {

		// Reset page to top
		let canParallax = supportsParallaxEffect();
		if (canParallax) {
			$('.moocow-parallax--base').scrollTo(0, 0);
		}

		// Prep loading animations
		addRouteLoader();
		this.routeElements.forEach(routeElement => routeElement.classList.add('moocow-route-before'));

		// Do the transition
		return delay(() => routeHandler(location, pathParts), this.transitionLengthMs)
			.then(() => {
				if (!canParallax) {
					window.scrollTo(0, 0);
				}

				// Finish loading animations
				removeLoader();
				this.routeElements.forEach(routeElement => routeElement.classList.add('moocow-route-after'));
				setTimeout(() => {
					this.routeElements.forEach(routeElement => {
						routeElement.classList.remove('moocow-route-before');
						routeElement.classList.remove('moocow-route-after');
					});
				}, this.transitionLengthMs);
			});
	}
}
