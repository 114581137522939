import fetchHtml from '../data/fetchHtml.js';
import render from '../rendering/render.js';

/**
 * Build a route handler for viewing a list of all artwork by a category.
 *
 * @return {import('./RouteHandler.js').RouteHandler}
 */
export default function goToArtworksForCategory() {
	return (location, [, category]) => Promise.all([
		import('../rendering/renderArtworks.js'),
		fetchHtml(`/artwork/category/${category}`)
	])
		.then(render);
};
